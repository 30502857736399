import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
function Terms() {
    const domain = 'dune-league.top'
    return (
        <div className='TermsAndPrivacy'>
            <div className='TermsAndPrivacy-terms'>
                <h1 className='TermsAndPrivacy-terms-text-title'>About this project</h1>
                <p className='TermsAndPrivacy-terms-text'>This is a translation. In case of dispute, the French version will prevail: In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of June 21, 2004 on Confidence in the digital economy, known as LCEN, Users of the site {domain} these legal notices: The connection and navigation on the site {domain} by the User implies full and unreserved acceptance of these legal notices and our conditions of use. These are accessible on the site under the heading “Terms”.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 1: The publisher</h3>
                <p className='TermsAndPrivacy-terms-text'>The edition and the direction of the publication of the site {domain} are ensured by Earlam (Volunteer) and Dynamo (CM) at Shiro Games.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 2: The host</h3>
                <p className='TermsAndPrivacy-terms-text'>The host of the site {domain} are ensured by Earlam (Volunteer) and Azrou (SysAdmin) at Shiro Games.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 3: Access to the site</h3>
                <p className='TermsAndPrivacy-terms-text'>The site is accessible from any location, 7 days a week, 24 hours a day, except in cases of "force majeure", scheduled or unscheduled interruption and possibly resulting from a need for maintenance. In the event of modification, interruption or suspension of the services, the site {domain} cannot be held responsible.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 4: Data collection</h3>
                <p className='TermsAndPrivacy-terms-text'>The site {domain} is not exempt from declaration to the Commission Nationale Informatique et Libertés (CNIL) insofar as it collects data concerning users. The data we collect on the site {domain} are: Nothing. The data we collect on the bot are: the user's public discord identifier. We don't keep data that is no longer useful to us. We do not sell your data but we might share it: read the privacy section for more information.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 5: Cookies</h3>
                <p className='TermsAndPrivacy-terms-text'>We don't use cookies on this site</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 6: Intellectual property</h3>
                <p className='TermsAndPrivacy-terms-text'>Any use, reproduction, distribution, marketing, modification of all or part of the site {domain}, without the authorization of the Publisher (Shiro Games and Earlam) is prohibited and may result in legal action and prosecution as provided for in particular by the Code. intellectual property and the Civil Code.</p>
                <br />
                <p></p>
                <h3 className='TermsAndPrivacy-terms-text-title'>ARTICLE 7: Finally</h3>
                <p className='TermsAndPrivacy-terms-text'>This contract is valid for an indefinite period. The beginning of the use of the services of the site and/or the bot mark the application of the contract with regard to the User. This contract is subject to French law. The absence of amicable resolution of cases of dispute between the parties implies recourse to the competent French courts to settle the dispute. </p>
            </div>
            <div className='TermsAndPrivacy-privacy'>
                <h1 className='TermsAndPrivacy-privacy-title'>Privacy</h1>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 1: Data Collection</h3>
                <p className='TermsAndPrivacy-privacy-text'>Data collection is limited to the user's public Discord identifier. Once a player participates, the data includes: number of points, participations (and match reported). The only data we do not generate for the player is therefore their public Discord identifier, which is very limited. No sensitive data is collected. We share this data publicly for free; we do not sell it.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 2: User Consent</h3>
                <p className='TermsAndPrivacy-privacy-text'>The user gives their free and informed consent by registering with the bot the first time they declare a match and the score is validated, or when they are cited in a match declaration by another player and validate it. They consent to their public Discord identifier being displayed on the bot's leaderboard and on the web page, as well as the data we created for them (points, wins, losses, ratio). They are informed of their rights through a document available for reading at any time in this website section. This announcement clearly includes how this data is collected and shared, as well as the means at their disposal to hide or delete it completely.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 3: The Forgiveme Command</h3>
                <p className='TermsAndPrivacy-privacy-text'>Users have access to the /forgiveme command on discord, which will make the bot forget the number of participations of the player and reset their profile to 0 (as if they had never participated), thus causing them to disappear from the leaderboard. If they wish to permanently delete their data from our physical machines, they can request it from me via DM on Discord.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 4: Access to Personal Data</h3>
                <p className='TermsAndPrivacy-privacy-text'>Users are able to access their personal data and request to modify or delete it (right to erasure, or "right to be forgotten"). They must also be able to object to certain types of processing.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 5: Data Security</h3>
                <p className='TermsAndPrivacy-privacy-text'>The application implements appropriate technical and organizational security measures to protect personal data from loss, leakage, unauthorized access, or any other type of violation. We have ensured to create something simple yet secure.</p>
                <p></p>
                <br/>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 6: Data Breach Notification</h3>
                <p className='TermsAndPrivacy-privacy-text'>In case of data leaks, Shiro or Earlam can, if they wish, report this to the CNIL and must notify users via Discord (if necessary).</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 7: Integration of Data Protection Principles</h3>
                <p className='TermsAndPrivacy-privacy-text'>The application integrate data protection principles from the early stages of its development (and ensure that they are respected throughout the product lifecycle).</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 8: Data Protection Officer</h3>
                <p className='TermsAndPrivacy-privacy-text'>If necessary, Shiro or Earlam must appoint a data protection officer to ensure that personal data processing practices are GDPR compliant.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 9: Data Displayed Outside the EU</h3>
                <p className='TermsAndPrivacy-privacy-text'>The fact that the player's score is displayed outside the EU is not a problem for Shiro or for the players, as the data is concise and not sensitive.</p>
                <br/>
                <p></p>
                <h3 className='TermsAndPrivacy-privacy-title'>ARTICLE 10: Documentation of Compliance Measures</h3>
                <p className='TermsAndPrivacy-privacy-text'>If desired, Shiro or Earlam can document the measures taken to ensure GDPR compliance, particularly via data processing registers.</p>
                <br/>
                <p></p>
            </div>
            <Link to='/' className='TermsAndPrivacy-link'>Back to leaderboard</Link>
            <br/>
            <p></p>
            <br/>
            <p></p>
            <br/>
            <p></p>
        </div>

    );
}

export default Terms;
