import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
function About() {
    return (
        <div className='About'>
            <h2 className='About-text-title'>CONCEPTION AND IDEA</h2>
            <p className='About-text'>Agrathan, pmsag, LeXel and Symb</p>
            <br/>
            <p></p>
            <h2 className='About-text-title'>DEV FULLSTACK</h2>
            <p className='About-text'>Earlam</p>
            <br/>
            <p></p>
            <h2 className='About-text-title'>TESTERS</h2>
            <p className='About-text'>Deshiba and Zommbiebro</p>
            <br/>
            <p> </p>
            <br/>
            <p> </p>
            <br/>
            <Link to='/' className='About-link'>Back to leaderboard</Link>
        </div>
    );
}

export default About;
