import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import './App/index.scss';
import './index.scss';
import App from './App';
import About from './About';
import Terms from './Terms';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Footer() {
    return (
        <footer className="App-footer">
            <Link to='/about' className='App-footer-link'>© Earlam</Link>
            <div className='App-footer-text'> - </div>
            <Link to='/terms' className='App-footer-link'>Privacy & Terms</Link>
            <div className='App-footer-text'> - </div>
            <div className='App-footer-text'>Powered by</div>
            <a href='https://shirogames.com/' target='_blank' rel='noopener noreferrer' className='App-footer-link'>Shiro Games</a>
            <div className='App-footer-text'> & </div>
            <a href='https://www.funcom.com/' target='_blank' rel='noopener noreferrer' className='App-footer-link'>Funcom</a>
        </footer>
    );
}

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="*" element={<App />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);