import React, { useEffect, useState } from 'react';
import './index.scss';
import gif from '../byFuncom.gif';
import fire from '../fire.gif';
import fire2 from '../fire2.gif';

function App() {
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://api.dune-league.top') // https://api.dune-league.top // http://localhost:51005
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error.message}</p>;

    return (
        <div className="App-content">
            <img className='App-content-img' src={gif} alt='gif' />
            <br />
            <a href='https://discord.gg/ha8fDrgJSD' target='_blank' rel='noopener noreferrer' className='App-content-h1'>
                <br />
                <img className='App-content-h1-fire' src={fire} alt='fire gif' width={30} />
                <div className='App-content-h1-text'>DUNE LEAGUE</div>
                <img className='App-content-h1-fire' src={fire2} alt='fire gif' width={30} />
            </a>
            <div className='App-content-classement'>
                {data.length > 0 ? data.map((item, index) => (
                    <p key={index}>
                        {`${index + 1} - ${item.username} - ${item.points} - `}<code>{`(W:${item.wins} L:${item.losses} R:${item.ratio})`}</code>
                    </p>
                )) : <p>No data available</p>}                
            </div>
        </div>
    );
}

export default App;
